import * as React from "react";

import {
  OAuthProvider,
  getAuth,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import Layout from "../components/shared/layout";
import Navbar from "../components/navbar";
import { SecondaryPageTitle } from "../components/shared/secondary-page-elements";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";
import { initializeApp } from "firebase/app";

const SignInWithApple = () => {
  React.useEffect(() => {
    (async () => {
      // Configure firebase depending on where this is deployed since Sign in
      // with Apple doesn't work cross-envs
      let hostname = window.location.hostname;
      let configObject;

      if (
        hostname === "copilot.money" ||
        hostname === "copilot-production-22904.firebaseapp.com"
      ) {
        configObject = {
          apiKey: "AIzaSyAMgjkeOSkHj4J4rlswOkD16N3WQOoNPpk",
          authDomain: hostname,
          databaseURL: "https://copilot-production-22904.firebaseio.com",
          projectId: "copilot-production-22904",
          storageBucket: "copilot-production-22904.appspot.com",
          messagingSenderId: "445606440735",
          appId: "1:445606440735:web:b0ac6d52da1d4c16c90a2a",
          measurementId: "G-T4HCG2XFK0",
        };
      } else {
        configObject = {
          apiKey: "AIzaSyBylgNCqROGMreEeefeDiCbiC6aPVtER28",
          authDomain: "copilot-development.firebaseapp.com",
          databaseURL: "https://copilot-development.firebaseio.com",
          projectId: "copilot-development",
          storageBucket: "copilot-development.appspot.com",
          messagingSenderId: "82380978046",
          appId: "1:82380978046:web:1cba2c05a24685a9",
          measurementId: "G-LVE945QMQ6",
        };
      }

      initializeApp(configObject);
      const auth = getAuth();

      // Try to log in
      const redirectResult = await getRedirectResult(auth);

      // If it fails we redirect to apple
      if (redirectResult == null) {
        signInWithRedirect(auth, new OAuthProvider("apple.com"));
      } else {
        // Otherwise this is the return redirect
        // Get a token to log in the user in the app
        const functionResult = await httpsCallable(
          getFunctions(),
          "createCustomToken",
          null
        )(null);
        // Check which app we need to open
        const urlParams = new URLSearchParams(window.location.search);
        const environmentParam = urlParams.get("env") || "production";
        const environment = environmentParam.match(/^[\w-]+$/)
          ? environmentParam
          : "production";
        // Open app with the token as a parameter
        let deepLink = `com.copilot.${environment}://authenticated?token=${functionResult.data}`;
        // Also send user creation date if available for validation
        const createdAt = redirectResult.user.metadata.createdAt;
        if (createdAt) {
          deepLink = `${deepLink}&timestamp=${createdAt}`;
        }
        window.location = deepLink;
      }
    })();
  });

  return (
    <Layout>
      <Seo title={"Sign in with Apple | Copilot"} />
      <DownloadModal />
      <Navbar />
      <Section>
        <Grid>
          <SecondaryPageTitle>Signing you in...</SecondaryPageTitle>
        </Grid>
      </Section>
      <Footer />
    </Layout>
  );
};

export default SignInWithApple;
